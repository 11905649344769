// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catalog-list {
  width: 100%;
}
.catalog-list__items {
  display: grid;
  grid-template-columns: repeat(auto-fill, 17.1875rem);
  justify-content: space-around;
  width: 100%;
  height: max-content;
  grid-gap: 2.5rem;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .catalog-list__items {
    padding: 0 1rem;
    grid-template-columns: repeat(auto-fill, 9.6875rem);
    grid-gap: 1.5rem;
    column-gap: 1.125rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/CatalogList/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,aAAA;EACA,oDAAA;EACA,6BAAA;EACA,WAAA;EACA,mBAAA;EACA,gBAAA;EACA,sBAAA;AACJ;AACI;EATF;IAUI,eAAA;IACA,mDAAA;IACA,gBAAA;IACA,oBAAA;EAEJ;AACF","sourcesContent":[".catalog-list {\n  width: 100%;\n\n  &__items {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, 17.1875rem);\n    justify-content: space-around;\n    width: 100%;\n    height: max-content;\n    grid-gap: 2.5rem;\n    box-sizing: border-box;\n\n    @media (max-width: 768px) {\n      padding: 0 1rem;\n      grid-template-columns: repeat(auto-fill, 9.6875rem);\n      grid-gap: 1.5rem;\n      column-gap: 1.125rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
