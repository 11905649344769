// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-more {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: rgba(var(--orange));
  line-height: 1.625rem;
  font-weight: 500; /* Safari */
  transition-duration: 0.4s;
}
.button-more__icon {
  height: 1rem;
  margin-left: 0.5rem;
}
.button-more:hover {
  color: rgba(var(--orange-300));
}`, "",{"version":3,"sources":["webpack://./src/components/ui/ButtonMore/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,0BAAA;EACA,qBAAA;EACA,gBAAA,EACA,WAAA;EACA,yBAAA;AACF;AACE;EACE,YAAA;EACA,mBAAA;AACJ;AAEE;EACE,8BAAA;AAAJ","sourcesContent":[".button-more {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  color: rgba(var(--orange));\n  line-height: 1.625rem;\n  font-weight: 500;\n  -webkit-transition-duration: 0.4s; /* Safari */\n  transition-duration: 0.4s;\n\n  &__icon {\n    height: 1rem;\n    margin-left: 0.5rem;\n  }\n\n  &:hover {\n    color: rgba(var(--orange-300));\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
