export const RUS = {
  translation: {
    tagline: 'Лучшее\nдля вашего интерьера',
    'business-inf-1':
      'Интерьерный салон Luxury Living работает с 2010 года и является официальным представителем на юге России\nвсемирно известных брендов: Hermes, Baccarat, Lalique, Christofle, Daum, Fendi Casa, Dolce&Gabbana Casa, Versace Home и другими.',
    'business-inf-2':
      'Опытные декораторы осуществляют под ключ полный спектр услуг от подбора материалов до оформления интерьера мебелью,\nпредметами столовой сервировки, текстилем и аксессуарами',
    more: 'Подробнее',
    more2: 'Подробнее о нас',
    catalog: 'Каталог',
    brands: 'Бренды',
    projects: 'Проекты',
    news: 'Новости',
    contacts: 'Контакты',
    serving: 'Сервировка',
    curtains: 'Шторы',
    furniture: 'Мебель',
    accessories: 'Аксессуары',
    'all-news-company': 'Все новости компании',
    'aware-of-trends': 'Хотите быть в курсе трендов?',
    'first-know':
      'Узнавайте первыми обо всех новинках и распродажах \nв нашей рассылке. Обещаем писать нечасто и только по делу',
    'first-know-mobile':
      'Узнавайте первыми обо всех новинках и распродажах в нашей рассылке.\nОбещаем писать нечасто и только по делу',
    subscribe: 'Подписаться',
    'you-agree': 'Нажимая на кнопку, вы соглашаетесь\nс',
    'privacy-policy': 'Политикой конфиденциальности',
    'email-error': 'Пожалуйста, проверьте, правильно ли указан e-mail',
    faq: 'Часто задаваемые вопросы',
    'know-about-us': 'Все, что нужно знать о нас',
    'return-policy': 'Политика возврата',
    'personal-data': 'Обработка персональных данных',
    wait: 'Подождите...',
    'success-subscribe': 'Вы успешно подписаны',
    'error-subscribe': 'Попробуйте позже',
    search: 'Поиск',
    email: 'E-mail',
    phone: '+7 (000) 000 00 00',
    'reset-filters': 'Сбросить фильтры',
    'product-count': 'Показать {{num}} {{value}}',
    filters: 'Фильтры',
    'not-found-search':
      'Ничего не удалось найти по вашему запросу.\nИзмените поисковый запрос или посмотрите наши рекомендации',
    'modal-feedback-subtitle':
      'Выберите удобный способ для связи\nи мы свяжемся с вами как можно быстрее,\nчтобы рассказать все детали',
    'contact-with-me': 'Связаться со мной',
    'fill-one-field': 'Заполните хотя бы одно поле',
    'correct-phone': 'Введите корректный номер',
    'contacted-soon': 'С вами скоро свяжутся',
    close: 'Закрыть',
    'brand-products': 'Товары бренда',
    'you-may-like': 'Вам может понравиться',
    country: 'Страна',
    present: 'Подарки',
    textile: 'Текстиль',
    carpets: 'Ковры',
    wallpaper: 'Обои',
    paints: 'Краски',
    'price-on-request': 'Цена по запросу',
    'check-availability': 'Уточнить наличие',
    home: 'Главная',
    'type-of-product': 'Тип продукта',
    brand: 'Бренд',
    'only-products-in-stock': 'Только товары в наличии',
    material: 'Материал',
    volume: 'Объем',
    phones: 'Телефоны',
    'opening-hours': 'Часы работы',
    address: 'Адрес',
    'apartment-decor': 'Декор квартиры 104 м²',
    'apartment-decor-description':
      'Проект, который мы сделали полностью сами и очень этим гордимся',
    'order-curtains': 'Заказать шторы',
    'contact-you':
      'Свяжемся с вами, чтобы уточнить детали и рассказать подробности наших услуг',
    'kitchen-living': 'Кухня-гостиная',
    bedroom: 'Спальня',
    'boy-nursery': 'Детская комната мальчика',
    'girl-nursery': 'Детская комната девочки',
    'decor-text1':
      'В интерьере квартиры, проект которой реализован Натальей Сапелкиной (архитектор, дизайнер), современная классика перемешалась с этническими нотками. Особую атмосферу создают яркие цветовые акценты и натуральные материалы в отделке.',
    'decor-text2':
      'Особенностью пространства можно назвать отделку натуральным камнем и его сочетания с другими материалами и элементами декора, как, например, в ванной.\n\nВсе материалы в интерьере хорошо взаимодействуют между собой, раскрывая замысел дизайнера. Общее цветовое решение пространства достаточно смелое: заказчикам нравятся насыщенные цвета, что особенно заметно в спальне старшего ребенка, а также в холле и кухне.',
    'decor-text3':
      'История знакомства дизайнера и заказчиков началась летом, перед выдачей ключей от квартиры владельцам — молодой активной паре с двумя детьми.\n\nВ числе пожеланий по планировке была отдельная спальня родителей, а также две детские комнаты. Реализовать это удалось благодаря объединению гостиной и кухни, так как изначально застройщиком было предусмотрено всего две спальни. Комната получилась светлая и очень удобная.',
    'decor-text4':
      'Реализована в сдержанных холодных оттенках синего и серого цветов. Имеет функциональную планировку и складной диван, чтобы получить больше свободного пространства.',
    'decor-text5':
      'Реализована в нежных пыльно-розовых и персиковых тонах. Универсальная стилистика подходит под любой возраст, а свет подчеркивает выразительные детали интерьера и глубину выбранных оттенков. Днем и вечером искусственный свет переплетается с лучами естественного, что создает комфортную обстановку в любое время дня.',
    'other-news': 'Другие новости',
  },
};
