// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 0.5rem;
  width: max-content;
  background-color: rgba(var(--grey-100));
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  font-family: var(--raleway);
  color: rgba(var(--grey-800));
  font-size: 1rem;
  line-height: 1.6;
  transition: all 0.3s;
  cursor: pointer;
}
@media (max-width: 768px) {
  .tab {
    padding: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.57;
  }
}
.tab_active {
  background-color: rgba(var(--grey-800));
  color: rgba(var(--white));
}
.tab_capitalize {
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/components/Tab/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,uCAAA;EACA,sBAAA;EACA,uBAAA;EACA,2BAAA;EACA,4BAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,eAAA;AACF;AACE;EAhBF;IAiBI,eAAA;IACA,mBAAA;IACA,iBAAA;EAEF;AACF;AAAE;EACE,uCAAA;EACA,yBAAA;AAEJ;AACE;EACE,0BAAA;AACJ","sourcesContent":[".tab {\n  display: flex;\n  align-items: center;\n  white-space: nowrap;\n  gap: 0.5rem;\n  width: max-content;\n  background-color: rgba(var(--grey-100));\n  border-radius: 0.25rem;\n  padding: 0.5rem 0.75rem;\n  font-family: var(--raleway);\n  color: rgba(var(--grey-800));\n  font-size: 1rem;\n  line-height: 1.6;\n  transition: all 0.3s;\n  cursor: pointer;\n\n  @media (max-width: 768px) {\n    padding: 0.5rem;\n    font-size: 0.875rem;\n    line-height: 1.57;\n  }\n\n  &_active {\n    background-color: rgba(var(--grey-800));\n    color: rgba(var(--white));\n  }\n\n  &_capitalize {\n    text-transform: capitalize;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
