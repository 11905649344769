// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-imgs {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
}

.list-img-item {
  position: relative;
}
.list-img-item__close {
  position: absolute !important;
  top: -25px;
  right: -30px;
}`, "",{"version":3,"sources":["webpack://./src/admin/components/ProductCard/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,SAAA;AACF;;AAEA;EACE,kBAAA;AACF;AACE;EACE,6BAAA;EACA,UAAA;EACA,YAAA;AACJ","sourcesContent":[".list-imgs {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 35px;\n}\n\n.list-img-item {\n  position: relative;\n\n  &__close {\n    position: absolute !important;\n    top: -25px;\n    right: -30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
