// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search {
  display: flex;
  align-items: center;
}
.search > div:not(.search__input_active) {
  border: 0px solid rgba(var(--blue-grey-300));
}
.search > div:not(.search__input_active) .input__field {
  padding: 0;
}
.search__input {
  max-width: 0;
  max-height: 0 !important;
  opacity: 0;
  transition: all 0.5s;
}
.search__input .input__icon {
  max-height: 0 !important;
  max-width: 0 !important;
}
.search__input .input__clear {
  max-height: 0 !important;
  max-width: 0 !important;
}
.search__input_active {
  width: 275px;
  max-width: 275px;
  max-height: 40px !important;
  opacity: 1;
}
@media (max-width: 768px) {
  .search__input_active {
    width: 100%;
    max-width: calc(100vw - 4rem);
  }
}
.search__input_active .input__icon {
  max-height: none !important;
  max-width: none !important;
}
.search__input_active .input__clear {
  max-height: none !important;
  max-width: none !important;
}
.search__close {
  margin-left: 0.5rem;
}
.search__icon {
  opacity: 0;
  max-height: 0 !important;
  max-width: 0 !important;
  transition: all 0.5s;
  transform: translateX(40px);
}
.search__icon_active {
  opacity: 1;
  transform: translateX(0);
  max-height: none !important;
  max-width: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Search/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,4CAAA;AACJ;AACI;EACE,UAAA;AACN;AAGE;EACE,YAAA;EACA,wBAAA;EACA,UAAA;EACA,oBAAA;AADJ;AAGI;EACE,wBAAA;EACA,uBAAA;AADN;AAII;EACE,wBAAA;EACA,uBAAA;AAFN;AAKI;EACE,YAAA;EACA,gBAAA;EACA,2BAAA;EACA,UAAA;AAHN;AAKM;EANF;IAOI,WAAA;IACA,6BAAA;EAFN;AACF;AAIM;EACE,2BAAA;EACA,0BAAA;AAFR;AAKM;EACE,2BAAA;EACA,0BAAA;AAHR;AAQE;EACE,mBAAA;AANJ;AASE;EACE,UAAA;EACA,wBAAA;EACA,uBAAA;EACA,oBAAA;EACA,2BAAA;AAPJ;AASI;EACE,UAAA;EACA,wBAAA;EACA,2BAAA;EACA,0BAAA;AAPN","sourcesContent":[".search {\n  display: flex;\n  align-items: center;\n\n  & > div:not(.search__input_active) {\n    border: 0px solid rgba(var(--blue-grey-300));\n\n    .input__field {\n      padding: 0;\n    }\n  }\n\n  &__input {\n    max-width: 0;\n    max-height: 0 !important;\n    opacity: 0;\n    transition: all 0.5s;\n\n    .input__icon {\n      max-height: 0 !important;\n      max-width: 0 !important;\n    }\n\n    .input__clear {\n      max-height: 0 !important;\n      max-width: 0 !important;\n    }\n\n    &_active {\n      width: 275px;\n      max-width: 275px;\n      max-height: 40px !important;\n      opacity: 1;\n\n      @media (max-width: 768px) {\n        width: 100%;\n        max-width: calc(100vw - 4rem);\n      }\n\n      .input__icon {\n        max-height: none !important;\n        max-width: none !important;\n      }\n\n      .input__clear {\n        max-height: none !important;\n        max-width: none !important;\n      }\n    }\n  }\n\n  &__close {\n    margin-left: 0.5rem;\n  }\n\n  &__icon {\n    opacity: 0;\n    max-height: 0 !important;\n    max-width: 0 !important;\n    transition: all 0.5s;\n    transform: translateX(40px);\n\n    &_active {\n      opacity: 1;\n      transform: translateX(0);\n      max-height: none !important;\n      max-width: none !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
