// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.filter__product-list {
  max-height: 304px;
}
.filter__brand-list {
  max-height: 468px;
}
.filter__color-list {
  max-height: 406px;
}
.filter__button {
  width: 100%;
  padding: 0.5rem 1rem !important;
}
.filter__switcher {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2rem;
  width: 17.1875rem;
}
.filter__switcher-label {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.625;
  color: rgba(var(--grey-800));
}`, "",{"version":3,"sources":["webpack://./src/components/Filter/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AACF;AACE;EACE,iBAAA;AACJ;AAEE;EACE,iBAAA;AAAJ;AAGE;EACE,iBAAA;AADJ;AAIE;EACE,WAAA;EACA,+BAAA;AAFJ;AAKE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;EACA,iBAAA;AAHJ;AAKI;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,4BAAA;AAHN","sourcesContent":[".filter {\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n\n  &__product-list {\n    max-height: 304px;\n  }\n\n  &__brand-list {\n    max-height: 468px;\n  }\n\n  &__color-list {\n    max-height: 406px;\n  }\n\n  &__button {\n    width: 100%;\n    padding: 0.5rem 1rem !important;\n  }\n\n  &__switcher {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    height: 2rem;\n    width: 17.1875rem;\n\n    &-label {\n      font-size: 1rem;\n      font-weight: 600;\n      line-height: 1.625;\n      color: rgba(var(--grey-800));\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
