// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: rgba(var(--orange));
  font-weight: 300;
  color: rgba(var(--white));
  font-size: 1.1rem;
  font-family: sans-serif, serif;
}
.counter[data-length="2"] {
  font-size: 0.9rem;
}
.counter[data-length="3"] {
  font-size: 0.7rem;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Counter/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,cAAA;EACA,kBAAA;EACA,qCAAA;EAEA,gBAAA;EACA,yBAAA;EACA,iBAAA;EACA,8BAAA;AAAF;AAEE;EACE,iBAAA;AAAJ;AAGE;EACE,iBAAA;AADJ","sourcesContent":[".counter {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 1.5rem;\n  height: 1.5rem;\n  border-radius: 50%;\n  background-color: rgba(var(--orange));\n\n  font-weight: 300;\n  color: rgba(var(--white));\n  font-size: 1.1rem;\n  font-family: sans-serif, serif;\n\n  &[data-length='2'] {\n    font-size: 0.9rem;\n  }\n\n  &[data-length='3'] {\n    font-size: 0.7rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
