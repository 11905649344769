// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__lang-menu {
  margin-left: 1rem;
}
.header__nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
}
.header__search_active {
  width: 100%;
}
.header_white .nav-link {
  color: rgba(var(--white));
}

.nav-link {
  font-family: var(--merriweather);
  color: rgba(var(--grey-800));
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 400;
}
.nav-link.active {
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 1px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;AACE;EACE,iBAAA;AACJ;AAEE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;AAAJ;AAGE;EACE,WAAA;AADJ;AAKI;EACE,yBAAA;AAHN;;AAQA;EACE,gCAAA;EACA,4BAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;AALF;AAOE;EACE,0BAAA;EACA,0BAAA;EACA,8BAAA;AALJ","sourcesContent":[".header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  &__lang-menu {\n    margin-left: 1rem;\n  }\n\n  &__nav-menu {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    gap: 1.5rem;\n  }\n\n  &__search_active {\n    width: 100%;\n  }\n\n  &_white {\n    .nav-link {\n      color: rgba(var(--white));\n    }\n  }\n}\n\n.nav-link {\n  font-family: var(--merriweather);\n  color: rgba(var(--grey-800));\n  cursor: pointer;\n  font-size: 0.875rem;\n  font-weight: 400;\n\n  &.active {\n    text-decoration: underline;\n    text-underline-offset: 5px;\n    text-decoration-thickness: 1px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
