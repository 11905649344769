// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 3rem 0 1.5rem;
}
.breadcrumbs-items {
  display: flex;
  align-items: center;
  gap: 12px;
}
.breadcrumbs-icon {
  position: relative;
  top: 1px;
}
.breadcrumbs__item {
  font-family: var(--raleway);
  font-size: 1rem;
  line-height: 1.625;
  color: rgba(var(--grey-400));
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}
.breadcrumbs__item:not(.breadcrumbs__item_active):hover {
  color: rgba(var(--grey-600));
}
.breadcrumbs__item_active {
  color: rgba(var(--grey-800));
  cursor: auto;
}

.brand-card-page .breadcrumbs__item {
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/components/Breadcrumbs/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,sBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACJ;AAEE;EACE,kBAAA;EACA,QAAA;AAAJ;AAGE;EACE,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,4BAAA;EACA,eAAA;EACA,kCAAA;AADJ;AAGI;EACE,4BAAA;AADN;AAII;EACE,4BAAA;EACA,YAAA;AAFN;;AAOA;EACE,0BAAA;AAJF","sourcesContent":[".breadcrumbs {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  padding: 3rem 0 1.5rem;\n\n  &-items {\n    display: flex;\n    align-items: center;\n    gap: 12px;\n  }\n\n  &-icon {\n    position: relative;\n    top: 1px;\n  }\n\n  &__item {\n    font-family: var(--raleway);\n    font-size: 1rem;\n    line-height: 1.625;\n    color: rgba(var(--grey-400));\n    cursor: pointer;\n    transition: color 0.3s ease-in-out;\n\n    &:not(.breadcrumbs__item_active):hover {\n      color: rgba(var(--grey-600));\n    }\n\n    &_active {\n      color: rgba(var(--grey-800));\n      cursor: auto;\n    }\n  }\n}\n\n.brand-card-page .breadcrumbs__item {\n  text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
