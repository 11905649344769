// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-result-text__find {
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.625;
  font-weight: 400;
  color: rgba(var(--grey-600));
}
.search-result-text__find-count {
  font-weight: 600;
  color: rgba(var(--grey-800));
}
.search-result-text__find-count-skeleton {
  position: relative;
  overflow: hidden;
  width: 4.81rem;
  border-radius: 0.25rem;
  height: 1.3rem;
  background-color: rgba(var(--blue-grey-300));
}
.search-result-text__find-count-skeleton:after {
  content: "";
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
  animation: shimmer 1.4s infinite;
  background-image: linear-gradient(90deg, rgba(var(--white), 0) 0%, rgba(var(--white), 0.2) 20%, rgba(var(--white), 0.5) 60%, rgba(var(--white), 0));
}
.search-result-text__content {
  font-size: 2rem;
  line-height: 1.3;
  font-weight: 500;
  color: rgba(var(--grey-800));
  margin-top: 1.5rem;
}
@media (max-width: 768px) {
  .search-result-text__content {
    font-size: 1.5rem;
    margin-top: 0.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/SearchResultText/styles.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,4BAAA;AAAJ;AAEI;EACE,gBAAA;EACA,4BAAA;AAAN;AAEM;EACE,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,sBAAA;EACA,cAAA;EACA,4CAAA;AAAR;AAEQ;EACE,WAAA;EACA,kBAAA;EACA,QAAA;EACA,4BAAA;EACA,gCAAA;EACA,mJAAA;AAAV;AAYE;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,4BAAA;EACA,kBAAA;AAVJ;AAYI;EAPF;IAQI,iBAAA;IACA,kBAAA;EATJ;AACF","sourcesContent":[".search-result-text {\n  &__find {\n    display: flex;\n    align-items: center;\n    font-size: 1rem;\n    line-height: 1.625;\n    font-weight: 400;\n    color: rgba(var(--grey-600));\n\n    &-count {\n      font-weight: 600;\n      color: rgba(var(--grey-800));\n\n      &-skeleton {\n        position: relative;\n        overflow: hidden;\n        width: 4.81rem;\n        border-radius: 0.25rem;\n        height: 1.3rem;\n        background-color: rgba(var(--blue-grey-300));\n\n        &:after {\n          content: '';\n          position: absolute;\n          inset: 0;\n          transform: translateX(-100%);\n          animation: shimmer 1.4s infinite;\n          background-image: linear-gradient(\n            90deg,\n            rgba(var(--white), 0) 0%,\n            rgba(var(--white), 0.2) 20%,\n            rgba(var(--white), 0.5) 60%,\n            rgba(var(--white), 0)\n          );\n        }\n      }\n    }\n  }\n\n  &__content {\n    font-size: 2rem;\n    line-height: 1.3;\n    font-weight: 500;\n    color: rgba(var(--grey-800));\n    margin-top: 1.5rem;\n\n    @media (max-width: 768px) {\n      font-size: 1.5rem;\n      margin-top: 0.5rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
