// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catalog-card-photo-mobile__img {
  width: calc(100% - 2rem);
  height: 100%;
  object-fit: cover;
  border-radius: 0.75rem;
}
.catalog-card-photo-mobile__scroll {
  position: relative;
  margin: 1.5rem 1rem 0;
  height: 0.25rem;
  background-color: rgba(var(--grey-100));
}
.catalog-card-photo-mobile__scroll-item {
  position: absolute;
  height: 0.25rem;
  transition: transform 500ms ease-in-out;
  background-color: rgba(var(--grey-400));
}
.catalog-card-photo-mobile .swiper-slide {
  height: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/components/CatalogCardPhotoMobile/styles.scss"],"names":[],"mappings":"AACE;EACE,wBAAA;EACA,YAAA;EACA,iBAAA;EACA,sBAAA;AAAJ;AAGE;EACE,kBAAA;EACA,qBAAA;EACA,eAAA;EACA,uCAAA;AADJ;AAGI;EACE,kBAAA;EACA,eAAA;EACA,uCAAA;EACA,uCAAA;AADN;AAKE;EACE,uBAAA;AAHJ","sourcesContent":[".catalog-card-photo-mobile {\n  &__img {\n    width: calc(100% - 2rem);\n    height: 100%;\n    object-fit: cover;\n    border-radius: 0.75rem;\n  }\n\n  &__scroll {\n    position: relative;\n    margin: 1.5rem 1rem 0;\n    height: 0.25rem;\n    background-color: rgba(var(--grey-100));\n\n    &-item {\n      position: absolute;\n      height: 0.25rem;\n      transition: transform 500ms ease-in-out;\n      background-color: rgba(var(--grey-400));\n    }\n  }\n\n  .swiper-slide {\n    height: auto !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
