export const tabMap: any = {
  Все: 'all',
  Подарки: 'present',
  Сервировка: 'serving',
  Аксессуары: 'accessories',
  Текстиль: 'textile',
  Ковры: 'carpets',
  Обои: 'wallpaper',
  Краски: 'paints',
  Мебель: 'furniture',
};
