// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-logo {
  width: 80%;
  cursor: pointer;
}

.sidebar-toolbar {
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/admin/components/Sidebar/styles.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,eAAA;AACF;;AAEA;EACE,uBAAA;AACF","sourcesContent":[".sidebar-logo {\n  width: 80%;\n  cursor: pointer;\n}\n\n.sidebar-toolbar {\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
