// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editorClassName {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 0 8px;
}`, "",{"version":3,"sources":["webpack://./src/admin/components/FAQCard/styles.scss"],"names":[],"mappings":"AAAA;EACE,qCAAA;EACA,kBAAA;EACA,cAAA;AACF","sourcesContent":[".editorClassName {\n  border: 1px solid rgba(0, 0, 0, 0.23);\n  border-radius: 4px;\n  padding: 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
