// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lang-menu {
  font-family: var(--merriweather);
  font-size: 0.875rem;
  color: rgba(var(--grey-800));
  cursor: pointer;
}
.lang-menu_white {
  color: rgba(var(--white));
}`, "",{"version":3,"sources":["webpack://./src/components/LangMenu/styles.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,mBAAA;EACA,4BAAA;EACA,eAAA;AACF;AACE;EACE,yBAAA;AACJ","sourcesContent":[".lang-menu {\n  font-family: var(--merriweather);\n  font-size: 0.875rem;\n  color: rgba(var(--grey-800));\n  cursor: pointer;\n\n  &_white {\n    color: rgba(var(--white));\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
