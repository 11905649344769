export const ENG = {
  translation: {
    tagline: 'The best\nfor your interior',
    'business-inf-1':
      'The interior salon Luxury Living has been operating since 2010 and is the official representative of\nworld-famous brands in the south of Russia:Hermes, Baccarat, Lalique, Christofle, Daum, Fendi Casa, Dolce & Gabbana Casa, Versace Home, etc.',
    'business-inf-2':
      'The salon also offers wallpapers, fabrics, bedding, carpets of the best world factories. Experienced decorators provide a full range of turnkey services,\nfrom the selection of materials to interior design with furniture, tableware, textiles and accessories',
    more: 'More',
    more2: 'More about us',
    catalog: 'Catalog',
    brands: 'Brands',
    projects: 'Projects',
    news: 'News',
    contacts: 'Contacts',
    serving: 'Serving',
    curtains: 'Curtains',
    furniture: 'Furniture',
    accessories: 'Accessories',
    'all-news-company': 'All company news',
    'aware-of-trends': 'Do you want to be aware of trends?',
    'first-know':
      'Be the first to know about all new products and sales\nin our newsletter. We promise to write infrequently and only on business',
    'first-know-mobile':
      'Be the first to know about all new products and sales in our newsletter.\nWe promise to write infrequently and only on business',
    subscribe: 'Subscribe',
    'you-agree': 'By clicking on the button, you agree\nto',
    'privacy-policy': 'Privacy policy',
    'email-error': 'Please check if the e-mail is correct',
    faq: 'FAQ',
    'know-about-us': 'Everything you need to know about us',
    'return-policy': 'Return policy',
    'personal-data': 'Processing of personal data',
    wait: 'Wait...',
    'success-subscribe': 'You have successfully subscribed',
    'error-subscribe': 'Try later',
    search: 'Search',
    email: 'E-mail',
    phone: '+7 (000) 000 00 00',
    'reset-filters': 'Reset filters',
    filters: 'Filters',
    'product-count': 'Show {{num}} {{value}}',
    'not-found-search':
      "We couldn't find anything matching your request.\nChange your search query or see our recommendations",
    'modal-feedback-subtitle':
      'Choose a convenient method of communication\nand we will contact you as quickly as possible to tell\nyou all the details',
    'contact-with-me': 'Contact with me',
    'fill-one-field': 'Fill in at least one field',
    'correct-phone': 'Enter the correct number',
    'contacted-soon': 'You will be contacted soon',
    close: 'Close',
    'brand-products': 'Brand products',
    'you-may-like': 'You may like',
    country: 'Country',
    present: 'Present',
    textile: 'Textile',
    carpets: 'Carpets',
    wallpaper: 'Wallpaper',
    paints: 'Paints',
    'price-on-request': 'Price on request',
    'check-availability': 'Check availability',
    home: 'Home',
    'type-of-product': 'Type of product',
    brand: 'Brand',
    'only-products-in-stock': 'Only products in stock',
    material: 'Material',
    volume: 'Volume',
    phones: 'Phones',
    'opening-hours': 'Opening hours',
    address: 'Address',
    'apartment-decor': 'Apartment decor 104 m²',
    'apartment-decor-description':
      'A project that we did entirely ourselves and are very proud of it',
    'order-curtains': 'Order curtains',
    'contact-you':
      'We will contact you to clarify the details and tell you the details of our services',
    'kitchen-living': 'Kitchen-living room',
    bedroom: 'Bedroom',
    'boy-nursery': "Boy's nursery",
    'girl-nursery': "Girls' nursery",
    'decor-text1':
      'In the interior of the apartment, the project of which was implemented by Natalia Sapelkina (architect, designer), modern classics are mixed with ethnic notes. Bright color accents and natural materials in the decoration create a special atmosphere.',
    'decor-text2':
      "The special feature of the space is the natural stone finish and its combination with other materials and decorative elements, such as in the bathroom.\n\nAll the materials in the interior interact well with each other, revealing the designer's idea. The overall color scheme of the space is quite bold: the clients like rich colors, which is especially noticeable in the older child's bedroom, as well as in the hall and kitchen.",
    'decor-text3':
      "The story of the designer and the clients' acquaintance began in the summer, before the keys to the apartment were handed over to the owners - a young active couple with two children.\n\nAmong the wishes for the layout was a separate bedroom for the parents, as well as two children's rooms. This was realized by combining the living room and kitchen, since the developer initially provided for only two bedrooms. The room turned out to be bright and very comfortable.",
    'decor-text4':
      'It is realized in restrained cold shades of blue and gray. It has a functional layout and a folding sofa to get more free space.',
    'decor-text5':
      'It is realized in gentle dusty pink and peach tones. The universal style suits any age, and the light emphasizes the expressive details of the interior and the depth of the chosen shades. During the day and in the evening, artificial light intertwines with the rays of natural light, which creates a comfortable environment at any time of the day.',
    'other-news': 'Other news',
  },
};
