// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-photo-mobile {
  position: fixed;
  inset: 0;
  background-color: rgba(var(--white));
  z-index: 10;
  overflow: hidden;
  animation: 0.3s show ease;
}
.modal-photo-mobile__img {
  width: calc(100% - 2rem);
  border-radius: 0.75rem;
}
.modal-photo-mobile__close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}
.modal-photo-mobile__content {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.modal-photo-mobile__content .swiper {
  overflow: hidden !important;
}
.modal-photo-mobile__scroll {
  position: fixed;
  bottom: 2.5rem;
  left: 0;
  right: 0;
  margin: 0 1rem;
  height: 0.25rem;
  background-color: rgba(var(--grey-100));
}
.modal-photo-mobile__scroll-item {
  position: absolute;
  height: 0.25rem;
  transition: transform 500ms ease-in-out;
  background-color: rgba(var(--grey-400));
}`, "",{"version":3,"sources":["webpack://./src/components/modals/ModalPhotoMobile/styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,QAAA;EACA,oCAAA;EACA,WAAA;EACA,gBAAA;EACA,yBAAA;AACF;AACE;EACE,wBAAA;EACA,sBAAA;AACJ;AAEE;EACE,kBAAA;EACA,WAAA;EACA,aAAA;AAAJ;AAGE;EACE,aAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;AADJ;AAGI;EACE,2BAAA;AADN;AAKE;EACE,eAAA;EACA,cAAA;EACA,OAAA;EACA,QAAA;EACA,cAAA;EACA,eAAA;EACA,uCAAA;AAHJ;AAKI;EACE,kBAAA;EACA,eAAA;EACA,uCAAA;EACA,uCAAA;AAHN","sourcesContent":[".modal-photo-mobile {\n  position: fixed;\n  inset: 0;\n  background-color: rgba(var(--white));\n  z-index: 10;\n  overflow: hidden;\n  animation: 0.3s show ease;\n\n  &__img {\n    width: calc(100% - 2rem);\n    border-radius: 0.75rem;\n  }\n\n  &__close {\n    position: absolute;\n    top: 1.5rem;\n    right: 1.5rem;\n  }\n\n  &__content {\n    display: flex;\n    height: 100%;\n    align-items: center;\n    justify-content: center;\n\n    .swiper {\n      overflow: hidden !important;\n    }\n  }\n\n  &__scroll {\n    position: fixed;\n    bottom: 2.5rem;\n    left: 0;\n    right: 0;\n    margin: 0 1rem;\n    height: 0.25rem;\n    background-color: rgba(var(--grey-100));\n\n    &-item {\n      position: absolute;\n      height: 0.25rem;\n      transition: transform 500ms ease-in-out;\n      background-color: rgba(var(--grey-400));\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
