// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switcher {
  position: relative;
  display: inline-block;
  width: 2.125rem;
  height: 1.25rem;
}
.switcher input[type=checkbox] {
  display: none;
}
.switcher__ball {
  position: absolute;
  cursor: pointer;
  background-color: rgba(var(--grey-850), 0.16);
  border-radius: 0.625rem;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.switcher__ball::before {
  position: absolute;
  content: "";
  left: 1px;
  top: 1px;
  width: 1.125rem;
  height: 1.125rem;
  background-color: rgba(var(--white));
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.switcher input[type=checkbox]:checked + .switcher__ball::before {
  transform: translateX(14px);
}
.switcher input[type=checkbox]:checked + .switcher__ball {
  background-color: rgba(var(--blue-500));
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Switcher/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;EACA,eAAA;EACA,eAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,kBAAA;EACA,eAAA;EACA,6CAAA;EACA,uBAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,sCAAA;AAAJ;AAGE;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,QAAA;EACA,eAAA;EACA,gBAAA;EACA,oCAAA;EACA,kBAAA;EACA,+BAAA;AADJ;AAIE;EACE,2BAAA;AAFJ;AAKE;EACE,uCAAA;AAHJ","sourcesContent":[".switcher {\n  position: relative;\n  display: inline-block;\n  width: 2.125rem;\n  height: 1.25rem;\n\n  input[type='checkbox'] {\n    display: none;\n  }\n\n  &__ball {\n    position: absolute;\n    cursor: pointer;\n    background-color: rgba(var(--grey-850), 0.16);\n    border-radius: 0.625rem;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    transition: background-color 0.2s ease;\n  }\n\n  &__ball::before {\n    position: absolute;\n    content: '';\n    left: 1px;\n    top: 1px;\n    width: 1.125rem;\n    height: 1.125rem;\n    background-color: rgba(var(--white));\n    border-radius: 50%;\n    transition: transform 0.3s ease;\n  }\n\n  input[type='checkbox']:checked + .switcher__ball::before {\n    transform: translateX(14px);\n  }\n\n  input[type='checkbox']:checked + .switcher__ball {\n    background-color: rgba(var(--blue-500));\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
